<template>
    <v-container fluid>
        <h2>Acompanhamento de Nota Fiscal</h2>
        <p></p>
        <v-card-titile>
            <!-- <v-row>
                <v-col :xs="12" :sm="12" :md="2" :lg="2" :xl="2" :cols="12">
                    <v-text-field 
                        label="Competência: "
                        outlined
                        type="month">
                    </v-text-field>
                </v-col>
            </v-row> -->
            <v-row justify="center">
                <v-col>
                    <v-data-table 
                        :loading="loading" 
                        :items="items" 
                        :search="search" 
                        :headers="headers"
                    >
                        <template v-slot:top>
                            <v-row>
                                <v-col cols="14">
                                    <v-text-field 
                                        v-model="search" 
                                        label="Pesquisar" 
                                        class="mx-0"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="text-right">
                                    <Tab :current-item="headers" @refresh_atendimentos="get_notafiscal()" />
                                    <v-btn icon @click="get_notafiscal()"><v-icon>mdi-refresh</v-icon></v-btn>
                                    <Editar :currentItem="{}" />
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:[`item.editar`]="{ item }">
                            <Editar :currentItem="item" />
                        </template>
            
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-titile>
    </v-container>
</template>
<script>
import api from '../../http';


export default {
    name:"Notafiscal",
    components: {
        // AddNotas_fiscais: () => import('./AddAtendimento.vue')
    },
    data: () => ({
        loading: false,
        items: [],
        search: '',
        headers: [
            { text: "ID", sortable: true, value: "id" },
            { text: "Prestador", sortable: true, value: "" },
            { text: "Competênciar", sortable: true, value: "competencia" },
            { text: "Valor N.F.", sortable: true, value: "valor_nf" },
            { text: "Aprovado? ", sortable: true, value: "" },
            { text: "Antecipação? ", sortable: true, value: "antecipacao" },
            { text: "Valor N.F. Antecipada ", sortable: true, value: "" },
            { text: "Aceite N.F.? ", sortable: true, value: "aceite" },
            { text: "OBS ", sortable: true, value: "obs" },
            { text: "Editar", sortable: true, align: "end", value: "editar" },

        ],
    }),
    methods: {
        get_listar() {
            this.loading = true;
            api("notas_fiscais/listar/")
                .then((response) => {
                    this.listar = response.data;
                })
                .catch(() => {
                    alert("Erro no link ao solicitatar notas fiscais");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.get_listar()
    }
}
</script>